/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-named-default */
import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import queryString from 'query-string';
import BasicModal from '@cleverrealestate/clever-components-v2/dist/components/BasicModal';
import PreviewMode from '@cleverrealestate/clever-components-v2/dist/components/PreviewMode';
import useGlobalCTAClick from '../utils/useGlobalCtaClick';
import useGlobalTOCHandler from '../utils/toc.utils';
import renderContent from '../utils/inline-utils-v3';

const PreviewTemplate = () => {
  const [slug, setSlug] = useState('');
  const [uuid, setUUID] = useState(null);
  useGlobalTOCHandler();
  const [modalOpen, setModalOpen] = useGlobalCTAClick();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const query = queryString.parse(window.location.search);
      if (query.uuid) {
        console.log('Loading via uuid:', query.uuid);
        setUUID(query.uuid);
      } else {
        console.log('Loading via slug:', query.id);
        setSlug(query.id);
      }
    }
  }, []);
  return (
    <div style={{ background: 'white', minHeight: '100vh' }}>
      {(slug !== '' || uuid !== null) && <PreviewMode id={slug} uuid={uuid} renderFunction={renderContent} />}
      {
        modalOpen ? createPortal((
          <BasicModal
            closeDialog={() => setModalOpen(false)}
          />
        ), document.body) : null
      }
    </div>
  );
};

export default PreviewTemplate;
